import enData from "../../resume/en-data.yaml";
import vnData from "../../resume/vn-data.yaml";

export const languages = [
  {
    name: "Tiếng Việt",
    code: "vn",
    data: vnData,
    resumeLabels: {
      contacts: "Liên hệ",
      profile: "Giới thiệu",
      skills: "Kỹ năng",
      experience: "Kinh nghiệm",
      education: "Học tập",
      project: "Dự án",
    },
    projectLabels: {
      time: "Thời gian",
      position: "Vị trí",
      teamSize: "Thành viên",
      technologies: "Công nghệ",
      links: "Liên kết",
      responsibility: "Trách nhiệm",
      description: "Mô tả",
    },
    skillLabels: {
      programLanguages: "Ngôn ngữ lập trình",
      frameworksPlatforms: "Frameworks",
      databases: "Cơ sở dữ liệu",
      devOps: "DevOps",
      iot: "IoT & Robotics",
      testing: "Kiểm thử",
      languages: "Ngôn ngữ",
      developmentTools: "Công cụ phát triển",
      versionControl: "Quản lý phiên bản",
      networking: "Mạng",
      other: "Khác",
    },
    footer: "© Bản quyền thuộc về",
    updatedAt: "Cập nhật lần cuối",
    outstandingProject: "Dự án tiêu biểu"
  },
  {
    name: "English",
    code: "en",
    data: enData,
    resumeLabels: {
      contacts: "Contacts",
      profile: "Summary",
      skills: "Skills",
      experience: "Experience",
      education: "Education",
      project: "Projects",
    },
    projectLabels: {
      time: "Time",
      position: "Position",
      teamSize: "Team size",
      technologies: "Technologies",
      links: "Links",
      responsibility: "Responsibility",
      description: "Description",
    },
    skillLabels: {
      programLanguages: "Programming Languages",
      frameworksPlatforms: "Frameworks & Platforms",
      databases: "Databases",
      devOps: "DevOps",
      iot: "IoT & Robotics",
      testing: "Testing",
      languages: "Languages",
      developmentTools: "Development Tools",
      versionControl: "Version Control",
      networking: "Networking",
      other: "Other",
    },
    footer: "© All rights reserved",
    updatedAt: "Last updated",
    outstandingProject: "Key Project"
  },
];

export function getCurrentLanguage() {
  return languages[1];
}

export function getLanguages() {
  return languages;
}
